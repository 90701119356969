import React, { Component } from 'react'
import { connect } from 'react-redux'
import { languageSwitchItem } from '../actions/locales'	
import { bindActionCreators } from 'redux'

import Body from '../components/body/Body'

class BodyContainer extends Component {

	componentDidMount() {
		
	}

	render() {
		return (
			<div className={this.props.baseUrls.fontSize !== 0 ? "visual-impairment-content-font-size-" + this.props.baseUrls.fontSize : null}>
				<Body connectionStatus={this.props.connectionStatus} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        locales: state.i18n,
        baseUrls: state.base,
        localesReducer: state.localesReducer,
        mobileModeReducer: state.mobileModeReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer);