import React from 'react'
import i18next from 'i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setActiveToken, setPassiveToken } from '../../actions/localStates'

class Popup extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          selectedActivePath: "",
          selectedPassivePath: ""
      }

      this.selectActivePath = this.selectActivePath.bind(this)
      this.selectPassivePath = this.selectPassivePath.bind(this)
      this.confirmActive = this.confirmActive.bind(this)
      this.confirmPassive = this.confirmPassive.bind(this)
  }

  selectActivePath(e) {
    this.setState({selectedActivePath: e.target.innerText})
    console.log(e.target.innerText)
  }

  selectPassivePath(e) {
    this.setState({selectedPassivePath: e.target.innerText})
    console.log(e.target.innerText)
  }

  confirmPassive() {
    this.props.actions.setPassiveToken(this.state.selectedPassivePath)
    this.props.closePopup()
  }

  confirmActive() {
    this.props.actions.setActiveToken(this.state.selectedActivePath)
    this.props.closePopup()
  }

  render() {
    let mode = parseInt(this.props.pkLocalState.selectedMode, 10);
    return (
      <div className='popup'>
        <div className='popup_inner' style={{width: "400px", height: "350px"}}>
        {
          mode === 1
          ? <div className="card">
              <div className="card-header">
                  {i18next.t("pk:pkcsDevicesActiveTitle")}
              </div>
              <div className="card-body">
                <table className="fixed_header">
                  {
                  this.props.pkLocalState.activeTokenPath
                  ? <tbody style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem"}}>
                    {
                      this.props.pkLocalState.activeTokenPath.map((n, index) => {
                        var active = index % 2 === 0 ? "evenClassName" : "oddClassName"
                        var setSelectedPath = this.state.selectedActivePath === n ? "setSelectedPath" : null
                        return (<tr className={active} style={{"cursor": "pointer"}} key={index}>
                                  <td type="button" className={setSelectedPath + " row"} style={{marginLeft: "0px", marginRight: "0px", paddingBottom: "0px"}} onClick={this.selectActivePath}>{n}</td>
                                    <div className="row col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                                      <span className="col-12" style={{fontSize: "12px", marginLeft: "5px"}}>{i18next.t("serialLabel")}{this.props.pkLocalState.tokenInfo[index]["serial"]}</span>
                                      <span className="col-12" style={{fontSize: "12px", marginLeft: "5px"}}>{i18next.t("modelLabel")}{this.props.pkLocalState.tokenInfo[index]["model"]}</span>
                                    </div>
                                  </tr>)
                      })
                    }
                  </tbody> : null
                }
                </table>
                <button className="btn btn-default" style={{marginTop: "10px"}} onClick={this.confirmActive}>{i18next.t("pk:selectTitle")}</button>
                <button className="btn btn-default" style={{marginTop: "10px", marginLeft: "10px"}} onClick={this.props.closePopup}>{i18next.t("pk:cancelTitle")}</button>
              </div>
            </div> : null
        }

        {
          mode === 2
          ? <div className="card">
              <div className="card-header">
                  {i18next.t("pk:pkcsDevicesPassiveTitle")}
              </div>
              <div className="card-body">
                <table className="fixed_header">
                  {
                    this.props.pkLocalState.passiveTokenPath
                    ? <tbody style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem"}}>
                      {
                        this.props.pkLocalState.passiveTokenPath.map((n, index) => {
                          var active = index % 2 === 0 ? "evenClassName" : "oddClassName"
                          var setSelectedPath = this.state.selectedPassivePath === n ? "setSelectedPath" : null
                          return (<tr className={active} style={{"cursor": "pointer"}} key={index}>
                                  <td type="button" className={setSelectedPath + " row"} style={{marginLeft: "0px", marginRight: "0px", paddingBottom: "0px"}} onClick={this.selectPassivePath}>{n}</td>
                                    <div className="row col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                                      <span className="col-12" style={{fontSize: "12px", marginLeft: "5px"}}>{i18next.t("serialLabel")}{this.props.pkLocalState.tokenInfo[index]["serial"]}</span>
                                      <span className="col-12" style={{fontSize: "12px", marginLeft: "5px"}}>{i18next.t("modelLabel")}{this.props.pkLocalState.tokenInfo[index]["model"]}</span>
                                    </div>
                                  </tr>)
                        })
                      }
                    </tbody> : null
                  }
                </table>
                <button className="btn btn-default" style={{marginTop: "10px"}} onClick={this.confirmPassive}>{i18next.t("pk:selectTitle")}</button>
                <button className="btn btn-default" style={{marginTop: "10px", marginLeft: "10px"}} onClick={this.props.closePopup}>{i18next.t("pk:cancelTitle")}</button>
              </div>
            </div> : null
        }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
      pkLocalState: state.privateKeyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setActiveToken,
      setPassiveToken
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);