import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setVtcoTwoFactorCode } from '../../actions/localStates'

class TwoFactorConfirmPopup extends Component {

  constructor(props) {
      super(props);

      this.onTwoFactorCodeChanged = this.onTwoFactorCodeChanged.bind(this);

      this.handle2FKeyUp = this.handle2FKeyUp.bind(this, 'twoFactorInput');
      this.closePopup = this.closePopup.bind(this);
      this.closePopupCancel = this.closePopupCancel.bind(this);
  }

  componentDidMount() {
      this.props.actions.setVtcoTwoFactorCode("");
      var element = document.getElementById("2f");
      element.focus();
      document.addEventListener('keyup', this.handle2FKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handle2FKeyUp);
  }

  handle2FKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "twoFactorInput") {
          document.getElementById("submitPin").click();
          document.activeElement.blur();
      }
  }

  closePopup () {
    this.props.initAction(this.props.id)
  }

  closePopupCancel () {
    this.props.initAction(true)
    // this.props.actions.setNeed2fConfirm(false);
  }

  onTwoFactorCodeChanged (e) {
    this.props.actions.setVtcoTwoFactorCode(e.target.value)
  }

  render() {
    let isEnabled = this.props.login.vtcoTwoFactorCode.length >= 6
    ? true
    : false

    let title = this.props.title;

      return (
          <div className='popup'>
              <div className="popup_inner_2f">
                <div className="card">
                  <div className="card-header">
                      {i18next.t("confirmLabel")}&nbsp;{i18next.t(title)}
                  </div>
                  <div className="card-body">
                      <div className="form-group row">
                          <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div className="input-group">
                                <input id="2f" type="text" placeholder={i18next.t("oneTimeCodeTitle")} className="form-control" autoComplete="off" value={this.props.login.vtcoTwoFactorCode} onChange={this.onTwoFactorCodeChanged} />
                            </div>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-6 col-xs-12 col-sm-12" style={{marginBottom: "10px"}}>
                            <button className="btn btn-default btn-block" id="submitPin" disabled={!isEnabled} onClick={this.closePopup}>{i18next.t("pk:selectTitle")}</button>
                          </div>
                          <div className="col-md-6 col-xs-12 col-sm-12">
                            <button className="btn btn-default btn-block" onClick={this.closePopupCancel}>{i18next.t("closeLabel")}</button>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.privateKeyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setVtcoTwoFactorCode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorConfirmPopup);